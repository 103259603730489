<template>
    <v-tooltip top open-delay="700">
        <template v-slot:activator="{ on }">
            <v-switch
                :color="switchColour"
                hide-details
                :input-value="switchValue"
                inset
                v-bind="$attrs"
                v-on="on"
                @click="$emit('click', $event)"
                @change="$emit('change', $event)"
            >
                <template v-slot:label>
                    {{ switchLabel }}
                    <v-progress-circular
                        class="ml-2"
                        color="primary"
                        indeterminate
                        size="24"
                        width="4"
                        v-if="switchIsLoading"
                    />
                </template>
            </v-switch>
        </template>
        <span>{{ tooltipMsg }}</span>
    </v-tooltip>
</template>

<script>
export default {
    inheritAttrs: false,

    name: "customSwitch",
    props: {
        switchColour: { default: null, type: String },
        switchIsLoading: { false: true, type: Boolean },
        switchLabel: { default: null, type: String },
        switchValue: { default: false, type: Boolean },
        tooltipMsg: { default: null, type: String },
    },
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>

